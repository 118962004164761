import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import {
  ColumnManifestData,
  OptionsClass,
  SectionManifestData
} from "@/domain/entities/MainApp";
import jsPDF from "jspdf";
import autoTable, { ColumnInput } from "jspdf-autotable";
import capitalize from "lodash/capitalize";
import { AccountController } from "../../controllers/AccountController";
import { formatPriceRP } from "@/app/infrastructures/misc/Utils";
import JsBarcode from "jsbarcode";
import { convertQRCode } from "../receipt/module";
import formatDateBasedTimezoneValue from "@/app/infrastructures/misc/common-library/FormatDateBasedTimezoneValue";


const margin = {
  left: 8,
  right: 8,
  top: 4,
  bottom: 19
};
let doc: any = new jsPDF("l", "mm", "a4", true),
  roleType = "",
  title = "",
  headerSection: OptionsClass[][] = [],
  columnData: ColumnInput[] = [],
  x = margin.left,
  y = margin.top,
  xx = margin.left,
  currentSection = 1,
  nextSection = 1,
  startY = margin.top + 13,
  logo = "",
  qrcodeValue = "",
  barcodeValue = "",
  signature = false;

const type: { [x: string]: number } = {
    REGPACK: 12,
    LANDPACK: 14,
    ONEPACK: 12,
    INTERPACK: 14,
    DOCUPACK: 14,
    MIXPACK: 11,
    SDPACK: 10,
    BIGPACK: 11,
    DORPACK: 12,
    JAGOPACK: 13,
    BOSSPACK: 13.25,
    "": 13
  },
  sections = 2,
  spacing = 5,
  maxRight = doc.internal.pageSize.getWidth() - margin.right,
  printWidht = doc.internal.pageSize.width - (margin.left + margin.right),
  sectionWidth = (printWidht - (sections - 1) * spacing) / sections,
  signatureSpace = 10;

const headerSectionProcess = async (params: SectionManifestData) => {
  // set accound data
  params.headerDetailData.accountData = AccountController.accountData;
  roleType = params.headerDetailData.accountData.account_type_detail?.type;

  // manifest title
  title = `${params.featureName} Manifest`;

  /* HEADER SECTION DATA */
  // array in array for divide header data. ex: [[first-section],[second-section],[third-section],[...-section]]
  const roleName: { [x: string]: string } = {
    "sub-console": "Sub Consolidator"
  };
  const originCity = params.headerDetailData.accountData.account_location;
  const headerSectionsData: OptionsClass[] = [
    new OptionsClass({
      name: params.headerDetailData.key.name,
      value: params.headerDetailData.key.value,
      key: "key"
    }),
    new OptionsClass({
      name: `Nama ${roleName[roleType]}`,
      value: capitalize(params.headerDetailData.accountData.name),
      key: "accountTypeName"
    }),
    new OptionsClass({
      name: "Kota Asal",
      value: `${originCity?.city_code} - ${capitalize(
        originCity?.city_name.toLowerCase()
      )}`,
      key: "originCity"
    }),
    new OptionsClass({
      name: "Kota Tujuan",
      value: params.headerDetailData.destinationCity,
      key: "destinationCity"
    }),
    new OptionsClass({
      name: "Tanggal Dibuat",
      value: params.headerDetailData.isFormatDateUsingTimestamp ? formatDateBasedTimezoneValue(params.headerDetailData.createdAt,"DD MMMM YYYY, HH:mm:ss") : formatDateWithoutTime(params.headerDetailData.createdAt),
      key: "createdAt"
    }),
    new OptionsClass({
      name: "Dibuat Oleh",
      value: capitalize(params.headerDetailData.createdBy),
      key: "createdBy"
    }),
    new OptionsClass({
      name: "Total STT",
      value: String(params.data.length),
      key: "totalStt"
    }),
    new OptionsClass({
      name: "Total Koli",
      value: formatInputFloat(params.headerDetailData.totalPiece),
      key: "totalPiece"
    }),
    new OptionsClass({
      name: "Total Berat Kotor",
      value: `${formatInputFloat(params.headerDetailData.totalGrossWeight)} Kg`,
      key: "totalGrossWeight"
    }),
    new OptionsClass({
      name: "Total Berat Dimensi",
      value: `${formatInputFloat(
        params.headerDetailData.totalVolumeWeight
      )} Kg`,
      key: "totalVolumeWeight"
    }),
    new OptionsClass({
      name: "Ursa Code",
      value: params.headerDetailData.ursaCode,
      key: "ursaCode"
    })
  ];
  // get & sort list header section data
  const sectionData = [];
  for (const section of params.headerSection) {
    for (const detail of headerSectionsData) {
      if (detail.key === section) {
        sectionData.push(detail);
      }
    }
  }
  // divide header section data to some row
  const totalColumnHeader = 4;
  const row =
    Math.ceil(params.headerSection.length / totalColumnHeader) +
    (params.headerSection.length <= totalColumnHeader ? 1 : 0);
  let i = 0;

  do {
    headerSection.push(sectionData.splice(0, row));
    i += 1;
  } while (i <= Math.floor(params.headerSection.length / row));

  startY += headerSection[0].length * 5;
  /* END HEADER SECTION DATA */
};

const tableDataProcess = (params: SectionManifestData) => {
  /* TABLE DATA */
  const tableColumnsData = [
    {
      header: "No STT",
      dataKey: "sttNo"
    },
    {
      header: "Total Koli",
      dataKey: "totalPiece"
    },
    {
      header: "Berat Kotor",
      dataKey: "totalGrossWeight"
    },
    {
      header: "Penerima",
      dataKey: "receiver"
    },
    {
      header: "Biaya\nCOD",
      dataKey: "codFee"
    },
    {
      header: "Nama\ndan TTD",
      dataKey: "signature"
    }
  ];

  // get & sort list column data
  for (const section of params.columns) {
    for (const detail of tableColumnsData) {
      if (detail.dataKey === section) {
        columnData.push(detail);
      }
    }
  }
  // add propery no each rows
  if (params.columns.length) {
    columnData.unshift({
      header: "No.",
      key: "no"
    });
  }
  //   add column No.
  params.data = params.data.map(
    (item: ColumnManifestData, index: number) =>
      new ColumnManifestData({
        ...item,
        no: index + 1
      })
  );
  /* END TABLE DATA */
};

const onRenderHeaderdetail = () => {
  /* HEADER SECTION */
  if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
    // QR code
    let totalMaxRight = maxRight;
    const spaceX = 3;

    const qrCodeSquare = 24;
    totalMaxRight -= qrCodeSquare - 2;
    doc.addImage(
      qrcodeValue,
      "PNG",
      totalMaxRight,
      y - 2,
      qrCodeSquare,
      qrCodeSquare,
      "qrcode",
      "FAST",
      0
    );

    // vertical line
    totalMaxRight -= spaceX - 1;
    doc.line(totalMaxRight, y, totalMaxRight, y + 20);

    // Barcode
    const barcodeWidth = 37;
    totalMaxRight -= barcodeWidth + spaceX;
    doc.addImage(
      barcodeValue,
      "PNG",
      totalMaxRight,
      y - 0.8,
      barcodeWidth,
      10,
      "barcode",
      "FAST"
    );

    // vertical line
    totalMaxRight -= spaceX;
    doc.line(totalMaxRight, y, totalMaxRight, y + 8.5);

    // get logo
    const logoProperties = doc.getImageProperties(logo);
    const logoAspectRatio = logoProperties.height / logoProperties.width;

    const logoWidth = 40;
    totalMaxRight -= logoWidth + spaceX;
    doc.addImage(
      logo,
      "PNG",
      totalMaxRight,
      y,
      logoWidth,
      logoAspectRatio * logoWidth,
      `${roleType}-logo`,
      "FAST"
    );

    // title
    y += 6;
    doc.setFont("Poppins", "bold");
    doc.setFontSize(14);
    doc.setTextColor("#4d4d4d");
    doc.text(title, x, y);
    y += 4; // add space after title

    // horizontal line
    doc.setLineWidth(0.2);
    doc.line(x, y, maxRight - qrCodeSquare, y);

    // header detail data
    const valueGap = 29;
    const sectionGap = 62;
    for (const header of headerSection) {
      doc.setFontSize(7);
      doc.setTextColor("#4d4d4d");
      doc.setFillColor("#ebecf0");

      for (const [idx, item] of header.entries()) {
        y += 5;
        doc.setFont("Poppins", "semibold");
        doc.text(item.name, x, y);
        xx += valueGap;
        doc.setFont("Poppins", "normal");
        doc.text(": " + item.value, xx, y);

        if (idx === header.length - 1) {
          x += sectionGap;
          xx = x;
          y -= header.length * 5;
        } else {
          xx -= valueGap;
        }
      }
    }
  }
};

const onDidDrawCell = (data: any) => {
  // reset text cell
  for (const body of data.table.body) {
    // parse value with prefix or suffix
    if (data.column.dataKey === "sttNo") {
      body.cells["sttNo"].text = [""];
    }
  }

  if (data.column.dataKey === "sttNo" && data.section !== "head") {
    const { x: xCell, y: yCell } = data.row.cells["sttNo"];
    const productWidth =
      type[data.row.raw.productType] ||
      doc.getTextWidth(data.row.raw.productType) + 2.25;

    doc.setFontSize(6);
    doc.setTextColor("#4d4d4d");
    doc.text(data.row.raw.sttNo, data.cursor.x, yCell + 3);

    doc.setFillColor("#ebecf0");
    doc.roundedRect(
      xCell,
      yCell + 4.5,
      productWidth,
      data.cell.contentHeight - 1,
      0.5,
      0.5,
      "F"
    );
    doc.setFontSize(6);
    doc.setTextColor("#4d4d4d");
    doc.setFont("Poppins", "semibold");
    doc.text(data.row.raw.productType, data.cursor.x + 1, yCell + 7);

    doc.setFont("Poppins", "normal");
    doc.setFontSize(6);
    doc.setTextColor("#4d4d4d");
    doc.text(
      `${data.row.raw.totalPiece} Koli`,
      xCell + productWidth + 1,
      yCell + 7
    );
  }

  if (data.column.dataKey === "receiver" && data.row.section === "body") {
    const { text, raw, x: xCell, y: yCell, width } = data.row.cells.receiver;

    // fill text receiver name with white box
    const getNameAndPhone: string[] = text.filter((item: string) =>
      raw.split("\n")[0].includes(item)
    );
    const lastLineNameAndPhone = getNameAndPhone[
      getNameAndPhone.length - 1
    ].split("+");
    const lastLineName = lastLineNameAndPhone[0]?.toUpperCase();
    const widthLastName: number = xCell + doc.getTextWidth(lastLineName) + 1;
    const phoneNumber: string = lastLineNameAndPhone[1];

    // get full name
    const fullName: string = raw.split("\n")[0].split("+")[0];

    // add chip fill
    doc.setFillColor("#fff");
    getNameAndPhone.forEach((_: any, index: number) => {
      doc.roundedRect(xCell, yCell + (index * 3.1 || 0.6), width, 3, 1, 1, "F");
    });

    // set content & font
    doc.setFont("Poppins", "bold");
    doc.setFontSize(6);
    doc.text(fullName?.toUpperCase(), xCell + 1, yCell + 3, {
      maxWidth: width + 5,
      charSpace: -0.1
    });

    doc.setFont("Poppins", "normal");
    doc.text(
      phoneNumber,
      widthLastName,
      yCell + (getNameAndPhone.length > 1 ? getNameAndPhone.length * 2.75 : 3),
      {
        maxWidth: width
      }
    );
  }
};

const onWillDrawCell = (data: any) => {
  // add borders bottom each column
  doc.setDrawColor("#E0E0E0"); // set the border color
  doc.setLineWidth(0.2); // set the border with

  if (data.section === "head") {
    // draw top border each row
    doc.line(
      data.cell.x + data.cell.width,
      data.cell.y,
      data.cell.x,
      data.cell.y
    );
  }

  if (data.column.index === 0) {
    // draw left border
    doc.line(
      data.cell.x,
      data.cell.y + data.cell.height,
      data.cell.x,
      data.cell.y
    );
  } else if (data.column.index === columnData.length - 1) {
    // draw right border
    doc.line(
      data.cell.x + data.cell.width,
      data.cell.y,
      data.cell.x + data.cell.width,
      data.cell.y + data.cell.height
    );
  }

  // draw bottom border each row
  doc.line(
    data.cell.x,
    data.cell.y + data.cell.height,
    data.cell.x + data.cell.width,
    data.cell.y + data.cell.height
  );
};

const onDidDrawPage = (data: any) => {
  onRenderHeaderdetail();
  currentSection = nextSection;
  nextSection = (nextSection % sections) + 1;

  // set left margin which will controll x position of next section
  const shift = (nextSection - 1) * (sectionWidth + spacing);
  data.table.settings.margin.left = margin.left + shift;

  // if next section is not the fist, move to previous page so when
  // autoTable calls addPage() it will still be the same current page
  if (doc.internal.getNumberOfPages() === 2 && nextSection === 1) {
    data.table.settings.margin.top = margin.top;
  }

  if (nextSection > 1) {
    doc.setPage(doc.internal.getNumberOfPages() - 1);
  }

  /* END HEADER SECTION */
};

const signatureProcess = () => {
  let totalMaxRight = maxRight;
  const remainingVSpace =
    doc.internal.pageSize.height -
    margin.top -
    margin.bottom -
    doc.lastAutoTable.finalY;

  // jsPDF 1.4+ uses getWidth, <1.4 uses .width
  const pageSize = doc.internal.pageSize;
  const pageHeight = pageSize.height || pageSize.getHeight();

  if (currentSection === 1) {
    nextSection = currentSection;
    startY = doc.lastAutoTable.finalY + 5;
  } else {
    startY = pageHeight - margin.top - signatureSpace;
  }

  doc.setTextColor("#4d4d4d");
  doc.setFontSize(7);
  doc.setFont("Poppins", "normal");
  totalMaxRight -= doc.getTextWidth("Operation Supervisor");
  doc.text("Operation Supervisor", totalMaxRight, startY);
  doc.text(
    "(................................................)",
    totalMaxRight,
    startY + signatureSpace
  );

  totalMaxRight -=
    doc.getTextWidth("(................................................)") + 10;

  doc.text("Kurir", totalMaxRight + 10, startY);
  doc.text(
    "(................................................)",
    totalMaxRight,
    startY + signatureSpace
  );
};

const onResetData = () => {
  roleType = "";
  title = "";
  headerSection = [];
  columnData = [];
  x = margin.left;
  y = margin.top;
  xx = margin.left;
  currentSection = 1;
  nextSection = 1;
  startY = margin.top + 13;
  logo = "";
  qrcodeValue = "";
  barcodeValue = "";
};

export const generateManifest2ColumnLandscape = async (
  params: SectionManifestData
): Promise<void> => {
  doc = new jsPDF("l", "mm", "a4", true);

  qrcodeValue = await convertQRCode(params.headerDetailData.qrCodeValue);
  signature = params.signature;

  // generate barcode
  const barcodeId = "barcode";
  document.getElementById(barcodeId)?.remove();
  if (params.headerDetailData.key.value) {
    const barcodeImg = document.createElement("img");
    barcodeImg.setAttribute("id", barcodeId);
    barcodeImg.setAttribute("style", "display: none;");
    barcodeImg.setAttribute("alt", "barcode");
    JsBarcode(barcodeImg, params.headerDetailData.key.value, {
      displayValue: false
    });
    document.body.appendChild(barcodeImg);
    barcodeValue =
      document.getElementById("barcode")?.getAttribute("src") || "";
  }

  headerSectionProcess(params);
  tableDataProcess(params);

  // logo
  const importLogo = await import(
    `@/app/ui/assets/images/logo-${roleType}.png`
  );
  logo = importLogo.default;

  // add an initial empty page that will be delete later,
  // it is needed for the first setPage(previous_page) call
  doc.addPage();

  autoTable(doc, {
    theme: "plain",
    body: params.data,
    columns: columnData,
    styles: {
      font: "Poppins",
      halign: "left",
      fontSize: 6,
      textColor: "#4D4D4D"
    },
    headStyles: {
      fontStyle: "bold",
      fillColor: "#F5F6F7",
      cellPadding: {
        top: 2,
        right: 1,
        bottom: 2,
        left: 1
      }
    },
    columnStyles: {
      no: {
        cellWidth: "wrap"
      },
      sttNo: {
        cellWidth: 24
      },
      receiver: {
        minCellHeight: 10
      },
      signature: {
        minCellWidth: 14
      }
    },
    bodyStyles: {
      fontStyle: "normal",
      valign: "top",
      cellPadding: {
        top: 1.2,
        right: 1.3,
        bottom: 1.2,
        left: 1.3
      },
      cellWidth: "auto",
      lineColor: "#4D4D4D"
    },
    tableWidth: sectionWidth,
    margin: {
      left: margin.left + (nextSection - 1) * (sectionWidth + spacing),
      top: startY,
      bottom: margin.bottom
    },
    startY,
    rowPageBreak: "avoid", // avoid breaking rows into multiple sections
    didDrawPage(data: any) {
      onDidDrawPage(data);
    },
    willDrawCell: (data: any) => {
      onWillDrawCell(data);
    },
    didDrawCell: (data: any) => {
      onDidDrawCell(data);
    },
    didParseCell: (data: any) => {
      for (const body of data.table.body) {
        // parse value with prefix or suffix
        if (data.column.dataKey === "codFee") {
          body.cells["codFee"].text = [`${formatPriceRP(body.raw["codFee"])}`];
        }
        if (data.column.dataKey === "totalGrossWeight") {
          body.cells["totalGrossWeight"].text = [
            `${formatInputFloat(body.raw["totalGrossWeight"])} Kg`
          ];
        }
      }
    }
  });

  // activate last page for further printing
  doc.setPage(doc.internal.getNumberOfPages());

  // Footer
  if (signature) {
    signatureProcess();
  }

  // delete unused empty page
  doc.deletePage(1);

  doc.setProperties({
    title
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
  onResetData();
};
